import React from 'react';
import './styles.css';

export default function Editor({ children }) {
  return (
    <div className="editor">
      <header className="headerEd">
        <h2>Editar Página</h2>
      </header>
      <div className="bodyEd">{children}</div>
    </div>
  );
}
