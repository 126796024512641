import React from 'react';
import './styles.css';

export function ButtonUpload({ id, label, buttonStyle, icon, onChange }) {
  return (
    <>
      <label htmlFor={id} className={`button ${buttonStyle}`}>
        <img src={icon} alt="" />
        {label}
      </label>
      <input
        type="file"
        accept={['image/jpeg', 'image/png']}
        id={id}
        className="button"
        onChange={onChange}
      />
    </>
  );
}

export function Button({ id, label, buttonStyle, icon, onClick }) {
  return (
    <button
      type="button"
      id={id}
      className={`button ${buttonStyle}`}
      onClick={onClick}
    >
      <img src={icon} alt="" />
      {label}
    </button>
  );
}
