import React from 'react';
import './styles.css';

export default function Sidebar({ profile, pageName }) {
  const pageNameParsed = pageName
    ? pageName.replace(/\s+/g, '-').toLowerCase()
    : '@pagename';
  const pageNick = pageName ? `@${pageNameParsed}` : '@pagename';
  return (
    <>
      <div className="sidebar">
        <div className="profile">
          <img className="profilePhoto" src={profile} alt="Perfil" />
        </div>
        <div className="profileName">
          <h1>{pageName}</h1>
          <span>{pageNick}</span>
        </div>
      </div>
    </>
  );
}
