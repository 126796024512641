import React from 'react';
import './styles.css';

export default function Avatar({ image }) {
  return (
    <div className="avatarContainer">
      <img className="avatarImage" src={image} alt="Perfil" />
    </div>
  );
}
