import React from 'react';
import './styles.css';
import Card from '../Card';
import coverImage from '../../assets/fb_cover_preview.svg';
import Avatar from '../Avatar';

export default function Post() {
  return (
    <Card>
      <a href="https://mvitor.dev/" className="headerPost">
        <Avatar image="https://s.gravatar.com/avatar/3eff73d3d5b1fae23e6567871e99b2c1?s=84" />

        <div className="mePost">
          <h2>Vitor Monteiro</h2>
          <span className="userName">@mluizvitor</span>
        </div>
      </a>
      <div className="postBody">
        <p>
          Para criar uma capa para Facebook perfeita, você deve levar em conta o
          tamanho total da imagem, a forma que é exibido no desktop e no mobile.
        </p>
        <p>
          As medidas da imagem devem ter no mínimo 820 píxels de largura e 360
          píxels de altura. Desse total 820x312 píxels são visualizados no
          desktop e 640x360 píxels são visualizados no mobile. Ou seja, você
          deve ter em mente que seu conteúdo mais importante deve estar na zona
          segura para não passar despercebido nem no computador, nem no app.
        </p>
        <img
          src={coverImage}
          alt="Demonstração da imagem com áreas visíveis no desktop e mobile."
        />
      </div>
    </Card>
  );
}
