import React from 'react';
import './styles.css';

import fbCoverGlyph from '../../assets/logo.glyph.svg';

export default function Topbar({ appName }) {
  return (
    <>
      <div className="header">
        <img width="24" height="24" src={fbCoverGlyph} alt="logo" />
        <h1 className="logo">{appName}</h1>
      </div>
    </>
  );
}
