import React, { useState, useEffect } from 'react';
import { getBase64Strings } from 'exif-rotate-js/lib';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Cover from './components/Cover';
import Feed from './components/Feed';
import Post from './components/Post';
import Downloads from './components/Downloads';

import coverImage from './assets/fb_cover_preview.svg';
import profileImage from './assets/logo.svg';
import coverIcon from './assets/btn.cover.svg';
import profileIcon from './assets/btn.profile.svg';
import deviceIcon from './assets/btn.device.svg';

import { ButtonUpload, Button } from './components/Button';
import Editor from './components/Editor';
import Avatar from './components/Avatar';

function App() {
  const [cover, setCover] = useState();
  const [profile, setProfile] = useState();
  const [pageName, setPageName] = useState();
  const [device, setDevice] = useState();

  useEffect(() => {
    if (window.outerWidth > 500) {
      document.documentElement.setAttribute('prefers-device', 'desktop');
      setDevice('desktop');
    } else {
      document.documentElement.setAttribute('prefers-device', 'mobile');
      setDevice('mobile');
    }

    setPageName('Facebook Cover Preview');
    setCover(coverImage);
    setProfile(profileImage);
  }, []);

  function handleMedia() {
    if (device === 'desktop') {
      setDevice('mobile');
      document.documentElement.setAttribute('prefers-device', 'mobile');
    } else {
      setDevice('desktop');
      document.documentElement.setAttribute('prefers-device', 'desktop');
    }
  }

  async function processImage(image) {
    const data = await getBase64Strings(image, {
      maxSize: 1640,
    });
    return data;
  }

  async function handleCoverLoad(event) {
    const newImage = await processImage(event.target.files);
    setCover(newImage);
  }

  async function handleProfileLoad(event) {
    const newImage = await processImage(event.target.files);
    setProfile(newImage);
  }

  function handleProfileName(event) {
    const newProfileName = event.target.value
      ? event.target.value
      : 'Facebook Cover Preview';
    setPageName(newProfileName);
  }

  return (
    <div className="App">
      <Topbar appName="Facebook Cover Preview" />

      <Feed>
        <Cover cover={cover} />
        <Sidebar profile={profile} pageName={pageName} />

        <div className="mainBody">
          <div className="postsBody">
            <Editor>
              <div style={{ display: 'flex' }}>
                <Avatar image={profile} />
                <input
                  className="pageName"
                  type="text"
                  id="pageName"
                  onChange={handleProfileName}
                  placeholder="Nome da página?"
                />
              </div>

              <hr />

              <div className="buttonsInput">
                <ButtonUpload
                  icon={profileIcon}
                  buttonStyle="editorBtn"
                  id="profile"
                  label="Mudar Perfil"
                  onChange={handleProfileLoad}
                />
                <ButtonUpload
                  icon={coverIcon}
                  buttonStyle="editorBtn"
                  id="capa"
                  label="Mudar Capa"
                  onChange={handleCoverLoad}
                />
                <Button
                  icon={deviceIcon}
                  buttonStyle="editorBtn"
                  label="Mudar Dispositivo"
                  onClick={handleMedia}
                />
              </div>
            </Editor>

            <Post />
          </div>

          <Downloads />
        </div>
      </Feed>
    </div>
  );
}

export default App;
