import React from 'react';
import './styles.css';

import coverImage from '../../assets/fb_cover_preview.svg';
import downloadIcon from '../../assets/file.svg';
import Card from '../Card';
import { version } from '../../../package.json';

export default function Downloads() {
  const versionString = `Versão ${version}`;

  return (
    <div className="downloadPanel">
      <Card>
        <h2>Downloads</h2>
        <p>Aqui um arquivo de template para criação de capas para Facebook.</p>
        <a className="downloadItem" href={coverImage} download>
          <img src={downloadIcon} alt="file" aria-hidden="true" />
          <div>
            <h3 className="downloadTitle">Template em SVG</h3>
            <span className="downloadDescription">
              Configurado para usar no Inkscape.
            </span>
          </div>
        </a>
      </Card>
      <hr />
      <small className="footer">Criado por Vitor Monteiro - 2020</small>
      <small className="footer">{versionString}</small>
      <br />
      <small className="footer">
        Esse site não armazena mídia. As imagens carregadas por você não são
        enviadas para lugar algum e ficam armazenadas localmente até que a
        página seja recarregada ou fechada.
      </small>
    </div>
  );
}
